<template>
    <section class="tools page__tools" id="tools">
        <div class="tools__container">
            <h2 class="tools__title" data-aos="fade-up">Получите передовые инструменты, <span class="color-yellow">интернет-маркетинга,</span>
                которые <br>дают <img src="@/assets/img/icons/emo-dollar.svg" width="45" height="45" alt=""/>
                денежный
                результат в 2021 году</h2>
            <ol class="tools__items" data-aos="fade">
                <li class="tools__item">
                    <div class="tools__item-header"><span class="tools__item-number">1</span>
                        <h3 class="tools__item-title"><span class="color-yellow">Настраивайте</span><br> рекламу
                        </h3>
                    </div>
                    <div class="tools__item-text">
                        <p>За 1 день через контекстную рекламу, Instagram, ВКонтакте и Avito</p>
                    </div>
                </li>
                <li class="tools__item">
                    <div class="tools__item-header"><span class="tools__item-number">2</span>
                        <h3 class="tools__item-title"><span class="color-yellow">Запускайте</span><br> автоворонки
                            за&nbsp;день
                        </h3>
                    </div>
                    <div class="tools__item-text">
                        <p>Мы потратили сотни тысяч рублей и несколько лет, чтобы найти рабочие инструменты по
                            заработку в
                            интернете и отдаем их вам</p>
                    </div>
                </li>
                <li class="tools__item">
                    <div class="tools__item-header"><span class="tools__item-number">3</span>
                        <h3 class="tools__item-title"><span class="color-yellow">Формируйте</span><br> личный бренд
                        </h3>
                    </div>
                    <div class="tools__item-text">
                        <p>Научитесь работать с соц. сетями, удерживать внимание подписчиков и проводить презентацию
                            продуктов
                            перед камерой</p>
                    </div>
                </li>
                <li class="tools__item">
                    <div class="tools__item-header"><span class="tools__item-number">4</span>
                        <h3 class="tools__item-title"><span class="color-yellow">Находите</span> клиентов<br> и&nbsp;партнеров
                        </h3>
                    </div>
                    <div class="tools__item-text">
                        <p>Получите пошаговую инструкцию по запуску потока клиентов и партнеров, а также готовые
                            инструменты
                            продаж</p>
                    </div>
                </li>
            </ol>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SectionTools"
    }
</script>

<style scoped>

</style>