<template>
  <div id="app">
    <v-header/>
    <main class="page__content">
      <router-view/>
    </main>
    <v-footer/>
  </div>
</template>

<script>
import "focus-visible";
import AOS from 'aos'
import VHeader from "@/components/VHeader";
import VFooter from "@/components/VFooter";

export default {
  name: "App",
  components: {
    VHeader,
    VFooter
  },
  mounted() {
    AOS.init({
      duration: 700,
      offset: 50,
      disable: () => window.innerWidth < 1024
    });
    this.onPageScroll()
  },
  methods: {
    onPageScroll() {
      [].forEach.call(document.querySelectorAll('.page'), function(page) {

        let timer;
        const modClass = "page--scrolled";
        const distance = 5;
        window.addEventListener("scroll", function(event){

          if (timer) {
            window.cancelAnimationFrame(timer);
          };

          timer = window.requestAnimationFrame(function(){

            let top = window.pageYOffset || document.documentElement.scrollTop;

            if ( (top > distance) && (!page.classList.contains(modClass)) ) {
              page.classList.add(modClass);
            };

            if ( (top <= distance) && (page.classList.contains(modClass)) ) {
              page.classList.remove(modClass);
            };

          })

        });

      });

    }
  }
};
</script>

<style lang="scss">

</style>
