<template>
    <section class="formats page__formats" id="formats">
        <div class="formats__container">
            <h2 class="formats__title" data-aos="fade-up">Занятия <img src="@/assets/img/icons/projector.svg"
                                                                       width="45"
                                                                       height="45" alt=""/> записаны в формате
                1-2-3. Увидел
                <span class="color-yellow">&gt;</span> сделал <span class="color-yellow">&gt;</span> получил
                результат</h2>
            <p class="formats__subtitle" data-aos="fade-up" data-aos-delay="500">Даже если у&nbsp;вас нет опыта
                продаж и&nbsp;навыков
                работы в&nbsp;интернете вы&nbsp;легко разберетесь с&nbsp;новой информацией</p>
            <div class="formats__items" data-aos="fade-up">
                <div class="formats__item formats__item--1">
                    <div class="formats__item-row">
                        <div class="formats__item-main">
                            <h3 class="formats__item-title">Все занятия и&nbsp;материалы находятся
                                на&nbsp;платформе</h3>
                            <div class="formats__item-note">
                                <svg class="svg-icon svg-icon--tutorial formats__item-note-icon">
                                    <use xlink:href="@/assets/img/sprite.svg#tutorial"></use>
                                </svg>
                                <span class="formats__item-note-caption">Вы получите запись занятий, материалы, чек-листы, таблицы и другие материалы, которые останется только внедрить.</span>
                            </div>
                        </div>
                        <div class="formats__item-visual"><img src="@/assets/img/formats-item-visual-13.png?v=2"
                                                               width="93"
                                                               height="178" alt=""/><img
                                src="@/assets/img/formats-item-visual-12.png?v=2" width="104" height="199"
                                alt=""/><img
                                src="@/assets/img/formats-item-visual-11.png?v=2" width="121" height="232" alt=""/>
                        </div>
                    </div>
                </div>
                <div class="formats__item formats__item--2">
                    <div class="formats__item-row">
                        <div class="formats__item-main">
                            <h3 class="formats__item-title">Вы можете заходить в&nbsp;любое удобное время и&nbsp;пересматривать
                                занятия в&nbsp;комфортном режиме</h3>
                            <div class="formats__item-note">
                                <svg class="svg-icon svg-icon--bulb formats__item-note-icon">
                                    <use xlink:href="@/assets/img/sprite.svg#bulb"></use>
                                </svg>
                                <span class="formats__item-note-caption">Все обучение происходит в интерактивной форме, которая позволяет лучше воспринять информацию</span>
                            </div>
                        </div>
                        <div class="formats__item-visual"><img src="@/assets/img/formats-item-visual-2.png"
                                                               width="524"
                                                               height="284" alt=""/></div>
                    </div>
                </div>
                <div class="formats__item formats__item--3">
                    <div class="formats__item-row">
                        <div class="formats__item-main">
                            <h3 class="formats__item-title">Всего больше 30&nbsp;ценных материалов </h3>
                            <p class="formats__item-subtitle">доступных в течение всего периода подписки</p>
                        </div>
                        <ol class="formats__item-list">
                            <li>Марафон по ведению и продвижению Инстаграм</li>
                            <li>Курс рся (яндекс) и кмс (гугл)</li>
                            <li>Курс по продвижению ютуб канала</li>
                            <li>Курс по созданию продающих сайтов</li>
                            <li>Курс по созданию чат-ботов VK, ТГ</li>
                            <li>Курс tik-tok продвижение/реклама</li>
                        </ol>
                        <div class="formats__item-more"><span
                                class="formats__item-more-caption">+24<br>Ценного<br>материала</span></div>
                    </div>
                </div>
            </div>
            <ul class="formats__features" data-aos="fade-up">
                <li class="formats__feature">
                    <h3 class="formats__feature-title">5 уроков</h3>
                    <p class="formats__feature-text">По созданию ботов</p>
                </li>
                <li class="formats__feature">
                    <h3 class="formats__feature-title">3 урока</h3>
                    <p class="formats__feature-text">По запуску бесплатного трафика</p>
                </li>
                <li class="formats__feature">
                    <h3 class="formats__feature-title">9 уроков</h3>
                    <p class="formats__feature-text">По таргетированной рекламе</p>
                </li>
                <li class="formats__feature">
                    <h3 class="formats__feature-title">1 марафон</h3>
                    <p class="formats__feature-text">По личному бренду</p>
                </li>
                <li class="formats__feature">
                    <h3 class="formats__feature-title">Готовые инструменты</h3>
                    <p class="formats__feature-text">По привлечению и продажам</p>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SectionFormats",
        mounted() {
            this.onInit();
        },
        methods: {
            onInit() {
                $(".formats__items").slick({
                    autoplay: false,
                    autoplaySpeed: 5000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    fade: true,
                    rows: 0,
                    adaptiveHeight: true
                });

            }
        }
    }
</script>

<style >

</style>