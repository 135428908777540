<template>
    <section class="faq page__faq" id="faq">
        <div class="faq__container">
            <div class="faq__row">
                <div class="faq__left">
                    <h2 class="faq__title">Мы всегда готовы вам помочь</h2>
                </div>
                <div class="faq__right">
                    <ul class="faq__items">
                        <li class="faq__item"
                            :class="{'active': item.isOpen}"
                            v-for="(item, index) of dropdownContent"
                            :key="index"
                        >
                            <h3 class="faq__question" tabindex="0"
                                @click="openDropdown(item)"
                            >
                                {{item.question}}
                            </h3>
                            <transition name="show-in" mode="out-in">
                                <div class="faq__answer"
                                     v-html="item.answer"
                                     v-if="item.isOpen"
                                ></div>
                            </transition>
                        </li>
                    </ul>
                </div>
            </div>
            <figure class="faq__person"><img src="@/assets/img/faq-person.png" width="270" height="500"
                                             alt="Ольга Фролова"/>
                <figcaption class="faq__person-caption">
                    <div class="faq__person-name">Ольга Фролова</div>
                    <div class="faq__person-about">Менеджер</div>
                </figcaption>
            </figure>
        </div>
    </section>
</template>

<script>
    import {FAQ_DROPDOWN_CONTENT} from "./faq-content";

    export default {
        name: "SectionFAQ",
        data() {
            return {
                dropdownContent: FAQ_DROPDOWN_CONTENT,
            }
        },
        methods: {
            openDropdown(item) {
                item.isOpen = !item.isOpen;
            }
        }
    }
</script>

<style scoped lang="scss">
    .show-in-enter-active {
        transition: .4s ease;
    }

    .show-in-leave-active {
        transition: .2s ease;
    }

    .show-in-enter,
    .show-in-leave-to {
        max-height: 0;
        opacity: 0;
    }

    .show-in-enter-to,
    .show-in-leave {
        max-height: 1000px;
    }
</style>