<template>
    <section class="saving page__saving" id="saving">
        <div class="saving__container">
            <p class="saving__pretitle" data-aos="fade-up">Общая стоимость всех сервисов и&nbsp;обучения</p>
            <h2 class="saving__title" data-aos="fade-up">Больше <span class="color-yellow">1 000 000</span>
                <svg class="svg-icon svg-icon--ruble">
                    <use xlink:href="@/assets/img/sprite.svg#ruble"></use>
                </svg>
            </h2>
            <h3 class="saving__list-title" data-aos="fade-up">Всего за 3&nbsp;675&nbsp;руб. вы:</h3>
            <ul class="saving__items">
                <li class="saving__item" data-aos="fade-right">
                    <svg class="svg-icon svg-icon--check-mark saving__item-icon">
                        <use xlink:href="@/assets/img/sprite.svg#check-mark"></use>
                    </svg>
                    <span>Научитесь настраивать рекламу и&nbsp;зарабатывать первые деньги в&nbsp;интернете</span>
                </li>
                <li class="saving__item" data-aos="fade-right">
                    <svg class="svg-icon svg-icon--check-mark saving__item-icon">
                        <use xlink:href="@/assets/img/sprite.svg#check-mark"></use>
                    </svg>
                    <span>Получите свой первый опыт работы в&nbsp;интернете и&nbsp;запустите себе поток новых партнеров</span>
                </li>
                <li class="saving__item" data-aos="fade-right">
                    <svg class="svg-icon svg-icon--check-mark saving__item-icon">
                        <use xlink:href="@/assets/img/sprite.svg#check-mark"></use>
                    </svg>
                    <span>Поймете, как&nbsp;реально получать финансовые результаты и&nbsp;станете востребованным специалистом</span>
                </li>
            </ul>
            <div class="saving__box"
                 data-aos="fade-left"
                 data-aos-delay="1500"
            >
                <img class="saving__box-icon"
                     src="@/assets/img/icons/lock.png"
                     width="60" height="60" alt=""
                />
                <h3 class="saving__box-title">Вы получаете доступ к&nbsp;платформе
                    <span class="color-yellow">за 3 675 руб.</span>
                </h3>
                <a class="button saving__button-login button--small"
                   href="/home"
                   role="button"
                >
                    <span class="button__caption">Войти в личный кабинет</span>
                </a>
            </div>
            <div class="saving__visual">
                <img src="@/assets/img/saving-visual.png"
                     width="590"
                     height="361"
                     alt=""
                     data-aos="fade"
                     data-aos-delay="1000"
                />
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SectionSaving"
    }
</script>

<style scoped>

</style>