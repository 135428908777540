<template>
  <footer class="footer page__footer">
    <div class="footer__container">
      <div class="footer__row">
        <p class="footer__privacy"><a href="#">Политика конфиденциальности</a></p>
        <p class="footer__copyright">&nbsp; PLATFORM 2021</p><!-- begin web-upakovka-->
        <!--                <a class="web-upakovka footer__web-upakovka" href="https://web-upakovka.ru/?utm_source=platform" target="_blank"><svg viewBox="0 0 22 22">
                                <path d="M1,6.5v5.5l2.3,1.5L7,16V8V7.5L1,4.8V6.5z"></path>
                                <path d="M9.7,7.2L9,7.5V8v8l5.4-3.7l0.5-0.4V6.5V4.8L9.7,7.2z"></path>
                                <path d="M13.2,3.1L14,2.7l-3.3-1.5L8,0L2,2.7L4.8,4L8,5.5L13.2,3.1z"></path>
                            </svg><span class="web-upakovka__caption">Разработка сайта</span><span class="web-upakovka__wave"></span></a>&lt;!&ndash; end web-upakovka&ndash;&gt;-->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
name: "VFooter"
}
</script>

<style scoped>

</style>