<template>
    <section class="services page__services" id="services">
        <div class="services__container">
            <div class="services__row">
                <div class="services__left">
                    <div class="services__screenshots"><img class="services__screenshot"
                                                            src="@/assets/img/services-screenshot-1.png?v=3"
                                                            width="460"
                                                            height="273" alt="" data-aos="fade-up"
                                                            data-aos-delay="100"/><img
                            class="services__screenshot" src="@/assets/img/services-screenshot-2.png?v=3"
                            width="460" height="263"
                            alt="" data-aos="fade-up" data-aos-delay="300"/><img class="services__screenshot"
                                                                                 src="@/assets/img/services-screenshot-3.png?v=3"
                                                                                 width="460 " height="263" alt=""
                                                                                 data-aos="fade-up"
                                                                                 data-aos-delay="600"/></div>
                    <div class="services__screenshots-pre"></div>
                </div>
                <div class="services__right">
                    <h2 class="services__title" data-aos="fade-up">Все необходимые сервисы и&nbsp;инструменты для&nbsp;привлечения
                        партнеров <span class="color-yellow">уже готовы</span></h2>
                    <ul class="services__items">
                        <li class="services__item" data-aos="fade-up">
                            <svg class="svg-icon svg-icon--bot services__item-icon">
                                <use xlink:href="@/assets/img/sprite.svg#bot"></use>
                            </svg>
                            <span class="services__item-caption">Конструктор чат-ботов, автовебинаров и воронок</span>
                        </li>
                        <li class="services__item" data-aos="fade-up">
                            <svg class="svg-icon svg-icon--horn services__item-icon">
                                <use xlink:href="@/assets/img/sprite.svg#horn"></use>
                            </svg>
                            <span class="services__item-caption">Сервисы для&nbsp;продвижения в&nbsp;соц.&nbsp;сетях</span>
                        </li>
                        <li class="services__item" data-aos="fade-up">
                            <svg class="svg-icon svg-icon--edit services__item-icon">
                                <use xlink:href="@/assets/img/sprite.svg#edit"></use>
                            </svg>
                            <span class="services__item-caption">Конструкторы баннеров</span></li>
                        <li class="services__item" data-aos="fade-up">
                            <svg class="svg-icon svg-icon--funnel services__item-icon">
                                <use xlink:href="@/assets/img/sprite.svg#funnel"></use>
                            </svg>
                            <span class="services__item-caption">Готовые презентации для&nbsp;привлечения партнеров</span>
                        </li>
                    </ul>
                </div>
            </div>
            <ul class="services__features">
                <li class="services__feature" data-aos="fade-right">
                    <h3 class="services__feature-title">Работа на&nbsp;платформе займёт <img
                            src="@/assets/img/icons/clock.svg"
                            width="33" height="45" alt=""/> 1-2
                        часа в&nbsp;день</h3>
                    <p class="services__feature-text">Вы легко сможете совмещать работу на платформе с вашей
                        основной
                        деятельность </p>
                </li>
                <li class="services__feature" data-aos="fade-left">
                    <h3 class="services__feature-title">Поддержка ‍<img src="@/assets/img/icons/curator.svg"
                                                                        width="31"
                                                                        height="45" alt=""/> куратора и&nbsp;сообщество
                        партнёров </h3>
                    <p class="services__feature-text">Вы никогда не останетесь один на один с непонятной темой или
                        сложными
                        заданиями. <b>Весь опыт сообщества</b> теперь приналдежит вам </p>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SectionServices",
        mounted() {
            this.onInit()
        },
        methods: {
            onInit() {
                $(".services__screenshots").each(function(){
                    var $slider = $(this);

                    function initSlider() {

                        if ( ($(window).width() < 768) && (!$slider.hasClass("active")) ) {

                            $slider.addClass("active");

                            $slider.slick({
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true,
                                arrows: false,
                                rows: 0
                            }).slick("slickFilter",".services__screenshot");
                        };

                        if ( ($(window).width() >= 768) && ($slider.hasClass("active")) ) {
                            $slider.slick("unslick");
                            $slider.removeClass("active");
                        };

                    };

                    initSlider();

                    var timer;
                    $(window).resize(function(){
                        clearTimeout(timer);
                        timer = setTimeout(initSlider, 300);
                    });

                });

            }
        }
    }
</script>

<style >

</style>