<template>
    <section class="earnings page__earnings" id="earnings">
        <div class="earnings__container">
            <h2 class="earnings__title" data-aos="fade-up">Как и <span class="color-yellow">сколько</span> вы&nbsp;будете
                зарабатывать на&nbsp;привлечении партнеров?</h2>
            <div class="earnings__buttons" data-aos="fade-up">
                <div class="earnings__button">1-3 месяц</div>
                <div class="earnings__button">3-6 месяц</div>
                <div class="earnings__button">6-12 месяцев</div>
            </div>
            <div class="earnings__items" data-aos="fade-up">
                <div class="earnings__item">
                    <p>В первый месяц 90% участников платформы зарабатывают первые деньги в интернете и окупают
                        стоимость в 2-3
                        раза.</p>
                    <p>На этом этапе средний доход: <b>7 - 11 т.р / мес.</b></p>
                    <ul class="earnings__list">
                        <li class="earnings__list-item">
                            <svg class="svg-icon svg-icon--chart3">
                                <use xlink:href="@/assets/img/sprite.svg#chart3"></use>
                            </svg>
                            <span class="earnings__list-caption">Вы начнете разбираться в маркетинге, поймете как привлекать клиентов и партнеров.</span>
                        </li>
                        <li class="earnings__list-item">
                            <svg class="svg-icon svg-icon--chart4">
                                <use xlink:href="@/assets/img/sprite.svg#chart4"></use>
                            </svg>
                            <span class="earnings__list-caption">Проведете первую презентацию, запустите первую рекламную кампанию и увидите первые результаты</span>
                        </li>
                    </ul>
                </div>
                <div class="earnings__item">
                    <p>Для того чтобы получить основные навыки и понять, как все устроено достаточно 3-6 месяцев</p>
                    <p>На этом этапе средний доход: <b>37 - 61 т.р в месяц</b></p>
                    <ul class="earnings__list">
                        <li class="earnings__list-item">
                            <svg class="svg-icon svg-icon--list">
                                <use xlink:href="@/assets/img/sprite.svg#list"></use>
                            </svg>
                            <span class="earnings__list-caption">Вы научитесь управлять своими результатами. Поймете, как повторить полученные результаты снова и снова. </span>
                        </li>
                        <li class="earnings__list-item">
                            <svg class="svg-icon svg-icon--chart5">
                                <use xlink:href="@/assets/img/sprite.svg#chart5"></use>
                            </svg>
                            <span class="earnings__list-caption">Каждый следующий клиент поможет вам отточить навыки продаж</span>
                        </li>
                    </ul>
                </div>
                <div class="earnings__item">
                    <p>90% пользователей платформы спустя год после регистрации выходят на <b>доход 100+ т.р.</b>
                        Большинство из
                        них нанимают 1-2 помощников освобождают свое время для семьи и жизни</p>
                    <ul class="earnings__list">
                        <li class="earnings__list-item">
                            <svg class="svg-icon svg-icon--do-not-call">
                                <use xlink:href="@/assets/img/sprite.svg#do-not-call"></use>
                            </svg>
                            <span class="earnings__list-caption">Не нужно писать или звонить в холодную. Что-то впаривать. Люди сами будут приходить и пытаться отдать вам свои деньги, чтобы попасть в ваш мир</span>
                        </li>
                        <li class="earnings__list-item">
                            <svg class="svg-icon svg-icon--rocket">
                                <use xlink:href="@/assets/img/sprite.svg#rocket"></use>
                            </svg>
                            <span class="earnings__list-caption">У вас будет сформирован личный бренд, который будет толкать вас вперед самостоятельно.</span>
                        </li>
                    </ul>
                </div>
            </div>
            <img class="earnings__visual" src="@/assets/img/earnings-visual.png" width="370" height="370" alt=""
                 data-aos="fade" data-aos-delay="1000"/>
        </div>
    </section>
</template>

<script>

    export default {
        name: "SectionEarnings",
        mounted() {
          this.onInit()
        },
        methods: {
            onInit() {
                $(".earnings").each(function(){
                    let $earnings = $(this);
                    let id = $earnings.attr("id") || "";

                    if (id.length < 1) {
                        id = "earnings-" + Math.random().toString(36).substr(2, 9);
                        $earnings.attr("id", id);
                    };

                    let buttons = "#" + id + " .earnings__buttons";
                    let items = "#" + id + " .earnings__items";

                    $(buttons).slick({
                        infinite: false,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        asNavFor: items,
                        arrows: false,
                        dots: false,
                        focusOnSelect: true,
                        swipeToSlide: true,
                        centerMode: false,
                        variableWidth: false,
                        rows: 0
                    });

                    $(items).slick({
                        autoplay: false,
                        autoplaySpeed: 5000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: false,
                        fade: true,
                        asNavFor:  buttons,
                        rows: 0,
                        adaptiveHeight: true
                    });

                    setTimeout(function(){
                        $(buttons).slick("slickSetOption", "speed", 500, true);
                        $(buttons).resize();
                        $(window).resize();
                    }, 0);

                });

            }
        }
    }
</script>

<style >

</style>