export const FAQ_DROPDOWN_CONTENT = [
    {
        isOpen: true,
        question: "Сколько времени понадобится на работу?",
        answer: ` <p>На начальном этапе достаточно 2-3 часа в день, с ростом дохода 
                   вы сможете сделать это основной деятельностью, посвятить больше вермени и получать больший результат</p>`,
    },
    {
        isOpen: false,
        question: "Как быстро я выйду на доход?",
        answer: `<p>По статистике в первый месяц партнёры выходят 
                    ﻿на доход до 11 тысяч рублей</p>`,
    }, {
        isOpen: false,
        question: "Меня не обманут?",
        answer: `<p>Мы дорожим своей репутацией и заинтересованы в росте дохода каждого партнёра. Компания растет только за счет роста партнёрской сети и если компания обманет хотя бы одного партнёра она навсегда лишится репутации</p>
                  `,
    }, {
        isOpen: false,
        question: "Что входит в стоимость?",
        answer: `<p>Лицензия на использования торгового робота на 3 месяца.</p>
                 <p>Обучающие материалы, инструменты автоматизированной работы в интернете, маркетинговые материалы для продвижения бизнеса в социальных сетях, готовые скрипты, пошаговое обучение, доступ к вознаграждению</p>`,
    }, {
        isOpen: false,
        question: "Какие варианты сотрудничества доступны?",
        answer: `<p>Вы можете сотрудничать, как клиент и пользоваться продуктом компании для создания пассивного источника дохода. Или стать партнёром приобретя франшизу готового бизнеса в которой есть все пошаговые алгоритмы позволяющие в короткое время выйти на доход</p>`,
    },
]

