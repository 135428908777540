<template>
    <!-- begin cs-->
    <section class="cs page__cs" id="cases">
        <div class="cs__container">
            <h2 class="cs__title" data-aos="fade-up">Вот результаты некоторых ребят:</h2>
            <div class="cs__buttons" data-aos="fade-up">
                <div class="cs__button" v-for="(item, n) of tabs" :key="n">
                    <div class="cs__button-in">
                        <div class="cs__button-name">{{item.author.name}}</div>
                        <div class="cs__button-info">{{item.author.profitCurrent.value}}</div>
                    </div>
                </div>
                <!--       <div class="cs__button">
                           <div class="cs__button-in">
                               <div class="cs__button-name">Cameron</div>
                               <div class="cs__button-info">165 000 ₽ / мес.</div>
                           </div>
                       </div>
                       <div class="cs__button">
                           <div class="cs__button-in">
                               <div class="cs__button-name">Nguyen</div>
                               <div class="cs__button-info">165 000 ₽ / мес.</div>
                           </div>
                       </div>
                       <div class="cs__button">
                           <div class="cs__button-in">
                               <div class="cs__button-name">Guy</div>
                               <div class="cs__button-info">165 000 ₽ / мес.</div>
                           </div>
                       </div>-->
            </div>
            <div class="cs__items" data-aos="fade-up">
                <div class="cs__item" v-for="(item, n) of tabs" :key="n">
                    <div class="cs__item-row">
                        <div class="cs__item-col">
                            <figure class="cs__item-photo" v-html="item.author.photo"></figure>
                        </div>
                        <div class="cs__item-col">
                            <figure class="cs__person">
                                <div class="cs__person-photo"><!--
                                    <img src="@/assets/img/cases/author.jpg" width="48" height="48" alt="Тимошевский Евгений"/>
                                --></div>
                                <figcaption class="cs__person-caption">
                                    <div class="cs__person-name">{{item.author.title}}</div>
                                    <div class="cs__person-about">{{item.author.status}}</div>
                                    <div class="cs__person-about">{{item.author.city}}</div>
                                </figcaption>
                            </figure><!-- begin progress-->
                            <div class="progress cs__item-progress">
                                <ul class="progress__items">
                                    <li class="progress__item"><span class="progress__value">{{item.author.profitBefore.value}}</span>
                                        {{item.author.profitBefore.text}}
                                    </li>
                                    <li class="progress__item"><span class="progress__value">{{item.author.profitCurrent.value}}</span>
                                        {{item.author.profitCurrent.text}}
                                    </li>
                                    <li class="progress__item"><span class="progress__value">{{item.author.registration}}</span>
                                        - Дата регистрации в
                                        проекте
                                    </li>
                                </ul>
                            </div><!-- end progress-->
                           <!-- <p class="cs__item-text">Евгений за <b>1 год работы</b> на платформе увеличил <b>доход в
                                5.5 раз и
                                переехал в г. Сочи</b></p>-->
                        </div>
                        <div class="cs__item-col">
                            <div class="cs__item-box">
                                <a class="cs__item-instagram-link" :href="item.inst.url" target="_blank">@instagram_case</a>
                                <blockquote class="cs__item-quote" v-html="item.inst.status"></blockquote>
                            </div>
                            <router-link class="button cs__item-button button--outline button--small"
                               :to="{name: 'user-case', params: {id: item.btn.to}}"
                               role="button"
                            >
                                <span class="button__caption">Читать историю {{item.btn.text}}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <!--        <div class="cs__item">
                            <div class="cs__item-row">
                                <div class="cs__item-col">
                                    <figure class="cs__item-photo"><img src="@/assets/img/cases/cs-1.jpg" width="296"
                                                                        height="256" alt=""/>
                                    </figure>
                                </div>
                                <div class="cs__item-col">
                                    <figure class="cs__person">
                                        <div class="cs__person-photo"><img src="@/assets/img/cases/author.jpg" width="48"
                                                                           height="48"
                                                                           alt="Тимошевский Евгений"/></div>
                                        <figcaption class="cs__person-caption">
                                            <div class="cs__person-name">Cameron Williamson</div>
                                            <div class="cs__person-about">32 года, г. Магнитогорск</div>
                                        </figcaption>
                                    </figure>&lt;!&ndash; begin progress&ndash;&gt;
                                    <div class="progress cs__item-progress">
                                        <ul class="progress__items">
                                            <li class="progress__item"><span class="progress__value">19.08.2019</span> -
                                                зарегистрировался
                                            </li>
                                            <li class="progress__item"><span class="progress__value">30 000 ₽</span>
                                                заработал за первый месяц
                                            </li>
                                            <li class="progress__item"><span class="progress__value">165 000 ₽ / мес.</span>
                                                - текущий заработок
                                            </li>
                                        </ul>
                                    </div>&lt;!&ndash; end progress&ndash;&gt;
                                    <p class="cs__item-text">Евгений за <b>1 год работы</b> на платформе увеличил <b>доход в
                                        5.5 раз и
                                        переехал в г. Сочи</b></p>
                                </div>
                                <div class="cs__item-col">
                                    <div class="cs__item-box"><a class="cs__item-instagram-link"
                                                                 href="#">@instagram_case</a>
                                        <blockquote class="cs__item-quote">
                                            <p>«…Для меня самыми главными критериями было, чтобы я не рисковал ничем и чтобы
                                                не было потолка в
                                                доходе»</p>
                                        </blockquote>
                                    </div>
                                    <a class="button cs__item-button button&#45;&#45;outline button&#45;&#45;small" href="case.html"
                                       role="button"><span
                                            class="button__caption">Читать историю Cameron</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="cs__item">
                            <div class="cs__item-row">
                                <div class="cs__item-col">
                                    <figure class="cs__item-photo"><img src="@/assets/img/cases/cs-1.jpg" width="296"
                                                                        height="256" alt=""/>
                                    </figure>
                                </div>
                                <div class="cs__item-col">
                                    <figure class="cs__person">
                                        <div class="cs__person-photo"><img src="@/assets/img/cases/author.jpg" width="48"
                                                                           height="48"
                                                                           alt="Тимошевский Евгений"/></div>
                                        <figcaption class="cs__person-caption">
                                            <div class="cs__person-name">Savannah Nguyen</div>
                                            <div class="cs__person-about">32 года, г. Магнитогорск</div>
                                        </figcaption>
                                    </figure>&lt;!&ndash; begin progress&ndash;&gt;
                                    <div class="progress cs__item-progress">
                                        <ul class="progress__items">
                                            <li class="progress__item"><span class="progress__value">19.08.2019</span> -
                                                зарегистрировался
                                            </li>
                                            <li class="progress__item"><span class="progress__value">30 000 ₽</span>
                                                заработал за первый месяц
                                            </li>
                                            <li class="progress__item"><span class="progress__value">165 000 ₽ / мес.</span>
                                                - текущий заработок
                                            </li>
                                        </ul>
                                    </div>&lt;!&ndash; end progress&ndash;&gt;
                                    <p class="cs__item-text">Евгений за <b>1 год работы</b> на платформе увеличил <b>доход в
                                        5.5 раз и
                                        переехал в г. Сочи</b></p>
                                </div>
                                <div class="cs__item-col">
                                    <div class="cs__item-box"><a class="cs__item-instagram-link"
                                                                 href="#">@instagram_case</a>
                                        <blockquote class="cs__item-quote">
                                            <p>«…Для меня самыми главными критериями было, чтобы я не рисковал ничем и чтобы
                                                не было потолка в
                                                доходе»</p>
                                        </blockquote>
                                    </div>
                                    <a class="button cs__item-button button&#45;&#45;outline button&#45;&#45;small" href="case.html"
                                       role="button"><span
                                            class="button__caption">Читать историю Savannah</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="cs__item">
                            <div class="cs__item-row">
                                <div class="cs__item-col">
                                    <figure class="cs__item-photo"><img src="@/assets/img/cases/cs-1.jpg" width="296"
                                                                        height="256" alt=""/>
                                    </figure>
                                </div>
                                <div class="cs__item-col">
                                    <figure class="cs__person">
                                        <div class="cs__person-photo"><img src="@/assets/img/cases/author.jpg" width="48"
                                                                           height="48"
                                                                           alt="Тимошевский Евгений"/></div>
                                        <figcaption class="cs__person-caption">
                                            <div class="cs__person-name">Guy Hawkins</div>
                                            <div class="cs__person-about">32 года, г. Сочи</div>
                                        </figcaption>
                                    </figure>&lt;!&ndash; begin progress&ndash;&gt;
                                    <div class="progress cs__item-progress">
                                        <ul class="progress__items">
                                            <li class="progress__item"><span class="progress__value">19.08.2019</span> -
                                                зарегистрировался
                                            </li>
                                            <li class="progress__item"><span class="progress__value">30 000 ₽</span>
                                                заработал за первый месяц
                                            </li>
                                            <li class="progress__item"><span class="progress__value">165 000 ₽ / мес.</span>
                                                - текущий заработок
                                            </li>
                                        </ul>
                                    </div>&lt;!&ndash; end progress&ndash;&gt;
                                    <p class="cs__item-text">Евгений за <b>1 год работы</b> на платформе увеличил <b>доход в
                                        5.5 раз и
                                        переехал в г. Сочи</b></p>
                                </div>
                                <div class="cs__item-col">
                                    <div class="cs__item-box"><a class="cs__item-instagram-link"
                                                                 href="#">@instagram_case</a>
                                        <blockquote class="cs__item-quote">
                                            <p>«…Для меня самыми главными критериями было, чтобы я не рисковал ничем и чтобы
                                                не было потолка в
                                                доходе»</p>
                                        </blockquote>
                                    </div>
                                    <a class="button cs__item-button button&#45;&#45;outline button&#45;&#45;small" href="case.html"
                                       role="button"><span
                                            class="button__caption">Читать историю Guy</span></a>
                                </div>
                            </div>
                        </div>-->
            </div>
            <router-link class="cs__banner" to="/cases" data-aos="fade"><span class="cs__banner-caption">Смотрите больше кейсов и&nbsp;историй успеха</span>
                <svg class="svg-icon svg-icon--logo cs__banner-logo">
                    <use xlink:href="@/assets/img/sprite.svg#logo"></use>
                </svg>
                <svg class="svg-icon svg-icon--arrow cs__banner-arrow">
                    <use xlink:href="@/assets/img/sprite.svg#arrow"></use>
                </svg>
            </router-link>
        </div>
    </section>
    <!-- end cs-->
</template>

<script>
    import {tabs} from "./tab-config";

    export default {
        name: "SectionCases",
        data: () => ({tabs}),
        mounted() {
            this.sliderInit();
        },
        methods: {
            sliderInit() {
                $(".cs").each(function () {
                    let $cs = $(this);
                    let id = $cs.attr("id") || "";

                    if (id.length < 1) {
                        id = "cs-" + Math.random().toString(36).substr(2, 9);
                        $cs.attr("id", id);
                    }
                    ;

                    let buttons = "#" + id + " .cs__buttons";
                    let items = "#" + id + " .cs__items";

                    $(buttons).slick({
                        infinite: false,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        asNavFor: items,
                        arrows: false,
                        dots: false,
                        focusOnSelect: true,
                        swipeToSlide: true,
                        centerMode: false,
                        variableWidth: false,
                        rows: 0,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    infinite: true,
                                    slidesToShow: 1,
                                    variableWidth: true
                                }
                            }
                        ]
                    });

                    $(items).slick({
                        autoplay: false,
                        autoplaySpeed: 5000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: false,
                        fade: true,
                        asNavFor: buttons,
                        rows: 0,
                        adaptiveHeight: true
                    });
                });
            }
        }
    }
</script>

<style>

</style>