<template>
    <section class="intro page__intro" id="intro">
        <div class="intro__container">
            <div class="intro__left">
                <h1 class="intro__title" data-aos="fade-up">Начните зарабатывать в&nbsp;интернете <span
                        class="intro__title-earnings"><span class="intro__title-label">от</span>&nbsp;30&nbsp;000
            <svg class="svg-icon svg-icon--ruble intro__title-ruble">
                                <use xlink:href="@/assets/img/sprite.svg#ruble"></use>
                            </svg> <span class="intro__title-label">мес.</span></span></h1>
                <div class="intro__note intro__note--1" data-aos="fade-left" data-aos-delay="500">
                    <svg class="svg-icon svg-icon--wifi">
                        <use xlink:href="@/assets/img/sprite.svg#wifi"></use>
                    </svg>
                    <span>Без навыков работы в&nbsp;интернете</span></div>
                <div class="intro__note intro__note--2" data-aos="fade-right" data-aos-delay="500">
                    <svg class="svg-icon svg-icon--hand-money">
                        <use xlink:href="@/assets/img/sprite.svg#hand-money"></use>
                    </svg>
                    <span>Даже без опыта продаж</span></div>
                <p class="intro__subtitle" data-aos="fade-up" data-aos-delay="500">Вы получите готовую систему и
                    набор
                    необходимых инструментов для заработка и привлечения партнеров</p>
                <a class="button intro__button" v-scroll-to="'income'" role="button" data-aos="fade-up" data-aos-delay="1000">
                    <span class="button__caption">Узнать подробнее</span>
                </a>
            </div>
            <div class="intro__right">
                <h3 class="intro__video-title">Посмотрите короткое <span class="color-yellow">видео</span> и
                    узнайте, за счет
                    чего вы сможете зарабатывать</h3>
                <a class="intro__video js-popup-video"
                   href="https://www.youtube.com/watch?v=0X1CQfTQqJw"
                   data-aos="fade">
                <div class="intro__video-cover">
                    <img src="@/assets/img/intro-video.jpg" width="610" height="426" alt=""/>
                </div>
                <span class="intro__video-play"></span>
            </a>
            </div>
        </div>
    </section>
</template>

<script>
    import 'magnific-popup'
    export default {
        name: "SectionIntro",
        mounted() {
            this.onInit();
        },
        methods: {
            openPopup(selector) {
                console.log(this)
                $.magnificPopup.open({
                    items:{
                        src: $(selector)
                    },
                    type:'inline',
                    midClick: true,
                    mainClass: 'mfp-zoom-in',
                    overflowY: 'scroll',
                    fixedContentPos: true,
                    fixedBgPos: true,
                    removalDelay: 300,
                    showCloseBtn: true,
                    closeBtnInside: true
                });
            },
            onInit() {
                $(".js-popup-video").magnificPopup({
                    disableOn: 100,
                    type: 'iframe',
                    removalDelay: 300,
                    mainClass: 'mfp-zoom-in',
                    preloader: true,
                    fixedContentPos: false
                });
                const vm = this;
/*
                $('.js-popup').click(function(e){
                    e.preventDefault();
                    var src = $(this).attr("href");
                    vm.openPopup(src);
                    return false;
                });

                $("[data-popup]").click(function(e){
                    var src = $(this).data("popup");
                    if (src.length) {
                        e.preventDefault()
                        vm.openPopup(src);
                    };
                });*/

                if (location.hash.indexOf("#popup") !== -1) {
                    this.openPopup(location.hash)
                };

                window.openPopup = this.openPopup;

                $(".js-popup-close, .popup__close").click(function(e) {
                    e.preventDefault();
                    $.magnificPopup.close();
                    // $(".mfp-container").click();
                    return false;
                });


            }
        }
    }
</script>

<style >

</style>