<template>
  <header class="header page__header" :class="{'header--nav-open': menuIsActive}">
    <div class="header__container">
      <!-- begin logo-->
      <div class="logo header__logo">
        <div class="logo__img">
          <router-link :to="{name: 'home'}"><img src="@/assets/img/logo.svg" width="120" height="25" alt="PLATFORM"/></router-link>
        </div>
        <div class="logo__text"><b>Обучение заработку</b> с&nbsp;помощью <b>автоворонок и&nbsp;чат ботов</b> по&nbsp;партнёрской
          программе
        </div>
      </div><!-- end logo-->
      <div class="header__nav">
        <!-- begin menu-->
        <nav class="menu header__menu">
          <ul class="menu__items">
            <li class="menu__item" v-for="(item, index) of navMenu"
                :key="index"
                :class="{disabled: item.disabled}"
            >
              <span class="menu__link"
                    :class="{'current': isActiveItem(item)}"
                    @click="goToPage(item.to)"
              >
                {{ item.title }}
              </span>
            </li>
          </ul>
        </nav><!-- end menu-->
        <a class="button header__button-login button--outline button--small"
           target="_blank"
           href="/cabinet"
           role="button"
        >
        <svg class="svg-icon svg-icon--door button__svg-icon">
          <use xlink:href="@/assets/img/sprite.svg#door"></use>
        </svg>
        <span class="button__caption">Войти в кабинет</span></a>
      </div><!-- begin btn-menu-->
      <button class="btn-menu header__btn-menu"
              type="button"
              aria-label="Меню"
              @click="menuIsActive = !menuIsActive"
              :class="{active: menuIsActive}"
      ></button><!-- end btn-menu-->
    </div>
  </header>
</template>


<script>
const navMenu = [
  {
    to: "home",
    title: "Главная"
  },
  {
    to: "courses",
    title: "Курсы",
    disabled: true,
  },
  {
    to: "cases",
    title: "Кейсы",
    children: [
        'user-case'
    ],
    disabled: false,
  },
]
export default {
  name: "VHeader",
  data() {
    return {
      navMenu,
      currentMenuItem: "home",
      menuIsActive: false,
    }
  },
  methods: {
    goToPage(name) {
      this.$router.push({name})
      this.menuIsActive = false;
    },
    isActiveItem(item) {
      return item.to === this.$route.name ||
          item.children?.includes(this.$route.name)
    }
  }
}
</script>

<style scoped lang="scss">
  .menu__item {
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      color: #ccc;
    }
  }
</style>