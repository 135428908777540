<template>
    <div>
        <section-intro/>
        <section-income/>
        <section-cases/>
        <section-earnings/>
        <section-tools/>
        <section-formats/>

        <section class="result page__result" id="result">
            <div class="result__container">
                <div class="result__box">
                    <div class="result__box-content">
                        <h2 class="result__title" data-aos="fade-up"><img src="@/assets/img/icons/diamond.svg"
                                                                          width="54"
                                                                          height="45" alt=""/>Результатом обучения
                            станет </h2>
                        <ul class="result__items">
                            <li class="result__item" data-aos="fade-right">
                                <svg class="svg-icon svg-icon--stats result__item-icon">
                                    <use xlink:href="@/assets/img/sprite.svg#stats"></use>
                                </svg>
                                <span>Понятный, эффективный и&nbsp;управляемый бизнес в&nbsp;интернете</span></li>
                            <li class="result__item" data-aos="fade-right">
                                <svg class="svg-icon svg-icon--money result__item-icon">
                                    <use xlink:href="@/assets/img/sprite.svg#money"></use>
                                </svg>
                                <span>Который приносит деньги и&nbsp;требует минимального контроля</span></li>
                        </ul>
                        <p class="result__text" data-aos="fade-up">Вы сможете разработать воронку самостоятельно или
                            сэкономить
                            время и&nbsp;воспользоваться уже готовыми вариантами, которые доказали свою
                            эффективность</p>
                    </div>
                    <img class="result__visual" src="@/assets/img/result-visual.png?v=2" width="404" height="480" alt=""
                         data-aos="fade" data-aos-delay="500"/>
                </div>
            </div>
        </section><!-- end result-->
        <!-- begin services-->
        <section-services/>
        <!-- end services-->
        <!-- begin founders-->
        <section class="founders page__founders" id="founders">
            <div class="founders__container">
                <h2 class="founders__title" data-aos="fade-up">«…Мы создали компанию, которая объединила знания и&nbsp;сервисы
                    для&nbsp;работы в&nbsp;интернете <b>и&nbsp;свела риски к&nbsp;нулю»</b></h2>
                <div class="founders__text">
                    <p data-aos="fade-right">За 2020 год мы заработали на&nbsp;маркетинге и&nbsp;привлечении партнеров
                        <b
                                class="color-yellow">больше 8&nbsp;млн.&nbsp;руб</b></p>
                    <p data-aos="fade-right">Мы знаем дорожку от&nbsp;фриланса и&nbsp;личных продаж до&nbsp;построения
                        команды из&nbsp;500&nbsp;человек</p>
                </div>
                <button class="button founders__button" v-scroll-to="'saving'" role="button" data-aos="fade-up"><span
                        class="button__caption">Хочу как вы</span></button>
                <div class="founders__founder founders__founder--1" data-aos="fade-up">
                    <p class="founders__name">Виталий Рябишин</p>
                    <p class="founders__about">Основатель</p>
                </div>
                <div class="founders__founder founders__founder--2" data-aos="fade-up">
                    <p class="founders__name">Этери Стефейкина</p>
                    <p class="founders__about">Основатель</p>
                </div>
                <div class="founders__founders-mob">
                    <img src="@/assets/img/founders@mob.png" width="472" height="472" alt=""/>
                </div>
            </div>
        </section><!-- end founders-->
        <!-- begin about-->
        <section class="about page__about" id="about">
            <div class="about__container">
                <div class="about__left">
                    <h2 class="about__title" data-aos="fade-up"><span class="color-yellow">PLATFORM</span> — сервис,
                        объединивший
                        процесс обучения и&nbsp;сервисы по&nbsp;запуску бизнеса в&nbsp;интернете</h2>
                </div>
                <div class="about__right">
                    <ul class="about__items">
                        <li class="about__item" data-aos="fade-left">
                            <svg class="svg-icon svg-icon--analysis about__item-icon">
                                <use xlink:href="@/assets/img/sprite.svg#analysis"></use>
                            </svg>
                            <span>Обучение дополняется актуальными курсами и постоянно улучшается, чтобы оставаться актуальным</span>
                        </li>
                        <li class="about__item" data-aos="fade-left">
                            <svg class="svg-icon svg-icon--money about__item-icon">
                                <use xlink:href="@/assets/img/sprite.svg#money"></use>
                            </svg>
                            <span>Тысячи пользователей со всей страны уже пользуются сервисами платформы и получают прибыль от продаж</span>
                        </li>
                        <li class="about__item" data-aos="fade-left">
                            <svg class="svg-icon svg-icon--diamond about__item-icon">
                                <use xlink:href="@/assets/img/sprite.svg#diamond"></use>
                            </svg>
                            <span>Каждый участник может привлекать экспертов или специалистов в платформу и получать бонусное вознаграждение</span>
                        </li>
                    </ul>
                </div>
            </div>
        </section><!-- end about-->
        <!-- begin advantages-->
        <section-advantages/>
        <!-- end advantages-->
        <!-- begin saving-->
        <section-saving/>
        <!-- end saving-->
        <!-- begin faq-->
        <section-faq/>
        <!-- end faq-->
    </div>
</template>

<script>
    import 'slick-carousel';
    import SectionCases from "../components/sections/cases/SectionCases";
    import SectionEarnings from "../components/sections/SectionEarnings";
    import SectionFormats from "../components/sections/SectionFormats";
    import SectionIncome from "../components/sections/SectionIncome";
    import SectionServices from "../components/sections/SectionServices";
    import SectionIntro from "../components/sections/SectionIntro";
    import SectionFaq from "../components/sections/faq/SectionFaq";
    import SectionTools from "../components/sections/SectionTools";
    import SectionSaving from "../components/sections/SectionSaving";
    import SectionAdvantages from "../components/sections/SectionAdvantages";

    export default {
        name: "HomePage",
        components: {
            SectionCases,
            SectionEarnings,
            SectionFormats,
            SectionIncome,
            SectionServices,
            SectionIntro,
            SectionFaq,
            SectionTools,
            SectionSaving,
            SectionAdvantages
        },
    }
</script>

<style lang="scss">

</style>