export const tabs = [
    {
        author: {
            photo: `<img src="${require('@/assets/img/cases/case-1/image-6.png')}" width="296" height="256" alt="Алена Скитченко"/>`,
            name: 'Алена Скитченко',
            title: 'Скитченко Алена 35 лет',
            status: 'Мама троих детей',
            city: 'Кишинев – Сальск - Ростов на Дону',
            profitBefore: {
                value: '25 000р в мес',
                text: ' - доход до проекта'
            },
            profitCurrent: {
                value: '100 000р в мес',
                text: ' - доход в проекте'
            },
            registration: '20.08.2019',
        },
        btn: {
            text: 'Алены',
            to: 'case-one'
        },
        inst: {
            url: 'https://www.instagram.com/miss_blondi001/',
            status: `<div>«Сама себе гуру💁‍♀</div>
                    <div>Слежу за детьми👧🏼👧🏼👧🏼 и фигурой</div>
                    <div>Робота трейдера запускаю 🚀🚀🚀</div>
                    <div>На пассиве получаю 💸💸💸</div>
                    <div>Деньги к профилю приучаю💰»</div>
                  `
        }
    },
    {
        author: {
            photo: `<img src="${require('@/assets/img/cases/case-2/image-6.png')}" width="296" height="256" alt="Юлия Алексеева"/>`,
            name: 'Юлия Алексеева',
            title: 'Алексеева Юлия 30 лет',
            status: 'Руководитель отдела магазиностроения',
            city: 'г. Ярославль',
            profitBefore: {
                value: '35 000 - 55 000 руб. мес',
                text: ' - доход до проекта'
            },
            profitCurrent: {
                value: '203 000р в мес',
                text: 'Максимальный чек на Платформе'
            },
            registration: '19.08.2019',
        },
        btn: {
            text: 'Юлии',
            to: 'case-two'
        },
        inst: {
            url: 'https://www.instagram.com/yuliabizzz/',
            status: `«Заработала >2,5🍋₽ сидя 🏠 с 📲 в руках
                    Вдохновила личным примером >1000 человек
                    Научу делать💰в соцсетях даже с 0 подписчиков
                    Познакомимся?😉👇🏻»`

        }

    }
]