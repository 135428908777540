<template>
    <section class="income page__income" id="income">
        <div class="income__container">
            <div class="income__row">
                <div class="income__left">
                    <h2 class="income__title" data-aos="fade">За счёт чего вы заработаете на&nbsp;<span
                            class="color-yellow">Platform?</span>
                    </h2>
                    <p class="income__subtitle" data-aos="fade" data-aos-delay="500">Продвигайте продукты платформы,
                        привлекайте
                        партнёров и&nbsp;получаете вознаграждения.</p>
                    <ul class="income__items">
                        <li class="income__item" data-aos="fade" data-aos-delay="1000">
                            <svg class="svg-icon svg-icon--without">
                                <use xlink:href="@/assets/img/sprite.svg#without"></use>
                            </svg>
                            <span>Без холодных звонков</span></li>
                        <li class="income__item" data-aos="fade" data-aos-delay="1100">
                            <svg class="svg-icon svg-icon--without">
                                <use xlink:href="@/assets/img/sprite.svg#without"></use>
                            </svg>
                            <span>Без впаривания</span></li>
                        <li class="income__item" data-aos="fade" data-aos-delay="1200">
                            <svg class="svg-icon svg-icon--without">
                                <use xlink:href="@/assets/img/sprite.svg#without"></use>
                            </svg>
                            <span>Без закупки товара</span></li>
                    </ul>
                </div>
                <div class="income__right">
                    <ol class="income__channels">
                        <li class="income__channel" data-aos="fade-left" data-aos-delay="500">
                            <svg class="svg-icon svg-icon--chart1">
                                <use xlink:href="@/assets/img/sprite.svg#chart1"></use>
                            </svg>
                            <span>Привлечение партнёров в&nbsp;программу</span></li>
                        <li class="income__channel" data-aos="fade-left" data-aos-delay="700">
                            <svg class="svg-icon svg-icon--chart2">
                                <use xlink:href="@/assets/img/sprite.svg#chart2"></use>
                            </svg>
                            <span>Продвижение продуктов и&nbsp;сервисов платформы</span></li>
                    </ol>
                </div>
            </div>
            <div class="income__total">
                <p class="income__total-pretitle">более 600 участников платформы уже привлекают партнёров</p>
                <div class="income__total-sum"><span class="income__total-value">98 576 590</span>
                    <svg class="svg-icon svg-icon--ruble">
                        <use xlink:href="@/assets/img/sprite.svg#ruble"></use>
                    </svg>
                </div>
                <p class="income__total-subtitle">зарабатывают вместе с&nbsp;ними от&nbsp;30&nbsp;000&nbsp;₽ до&nbsp;290&nbsp;000&nbsp;₽
                    в&nbsp;месяц </p>
            </div>
        </div>
    </section>
</template>

<script>
    require('waypoints/lib/noframework.waypoints');
    window.CountUp = require('../../libraries/countup');
    export default {
        name: "SectionIncome",
        mounted() {
            this.onInit()
        },
        methods: {
            onInit() {
                [].forEach.call(document.querySelectorAll('.income'), function(section) {
                    let numbers = section.querySelectorAll(".income__total-value");
                    let count = 0;
                    let waypoint = new Waypoint({
                        element: section,
                        handler: function(direction) {
                            if (direction == "down") {
                                if (count < 1) {
                                    numbers.forEach(function (el) {
                                        var value = el.textContent;
                                        value = parseInt(value.replace(/\s/g, ''));
                                        var numAnim = new CountUp(el, 0, value, 0, 3,{separator: " "});
                                        if (!numAnim.error) {
                                            numAnim.start();
                                        };
                                    });
                                    count = count + 1;
                                };
                            };
                        },
                        offset: "70%"
                    });
                });

            }
        }
    }
</script>

<style >

</style>