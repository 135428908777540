import Vue from 'vue';
import Router from "vue-router";

import HomePage from "@/pages/HomePage";
const Cases = () => import('../pages/cases/CasesPage');
const UserCase = () => import('../pages/cases/UserCase')

Vue.use(Router)

const routes = [
    {
        path: "/",
        name: "home",
        component: HomePage
    },
    {
        path: "/cases",
        name: "cases",
        component: Cases
    },
    {
        path: "/user-case/:id",
        name: "user-case",
        props: true,
        component: UserCase
    },
    {
        path: "*",
        redirect: {name: "home"}
    }
]

const router = new Router({
    mode: "history",
    routes
})

router.afterEach(() => {
    window.scrollTo(0, 0);
});

export default router
