import Vue from 'vue';
import App from "./App.vue";
import router from "./router";
import './assets/styles/main.scss'

window.$ = window.jQuery = require('jquery')
Vue.directive('scrollTo', {
    inserted(el, bindings) {
        el.addEventListener('click', async () => {
            const target = document.getElementById(bindings.value);
            const top =  window.scrollY + target.getBoundingClientRect().y;
            window.scrollTo({
                top,
                behavior: "smooth"
            })
        });
    },
})
Vue.config.productionTip = false;
new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
