<template>
    <section class="advantages page__advantages" id="advantages">
        <div class="advantages__container">
            <h2 class="advantages__title" data-aos="fade-up">Платформа не станет мертвым грузом</h2>
            <p class="advantages__subtitle" data-aos="fade-up">который можно посмотреть пару раз между делом и&nbsp;забросить
                со&nbsp;скуки потому, что</p>
            <ul class="advantages__items">
                <li class="advantages__item" data-aos="fade-up"><img class="advantages__item-icon"
                                                                     src="@/assets/img/icons/book.png" width="60"
                                                                     height="60"
                                                                     alt=""/>
                    <p class="advantages__item-text">Мы даем прикладные знания. Ничего лишнего и «на всякий случай»
                        делать не
                        придется — только необходимое.</p>
                </li>
                <li class="advantages__item" data-aos="fade-up"><img class="advantages__item-icon"
                                                                     src="@/assets/img/icons/money-bag.png"
                                                                     width="60"
                                                                     height="60" alt=""/>
                    <p class="advantages__item-text">Все занятия нацелены на максимально быструю окупаемость и рост
                        вашего
                        дохода</p>
                </li>
                <li class="advantages__item" data-aos="fade-up"><img class="advantages__item-icon"
                                                                     src="@/assets/img/icons/page.png" width="60"
                                                                     height="60"
                                                                     alt=""/>
                    <p class="advantages__item-text">Материал раскрыт доступно и ёмко, со схемами. Вы сможете
                        получить результат
                        с любым опытом</p>
                </li>
                <li class="advantages__item" data-aos="fade-up"><img class="advantages__item-icon"
                                                                     src="@/assets/img/icons/chart.png" width="60"
                                                                     height="60" alt=""/>
                    <p class="advantages__item-text">Программа составлена по нарастающей: вы двигаетесь от простого
                        к сложному.
                        В голове выстраивается система знаний, которой удобно пользоваться</p>
                </li>
                <li class="advantages__item" data-aos="fade-up"><img class="advantages__item-icon"
                                                                     src="@/assets/img/icons/chat.png" width="60"
                                                                     height="60"
                                                                     alt=""/>
                    <p class="advantages__item-text">Обучение ускорят и расширят бонусы: чаты, поддержка кураторов и
                        работа «на
                        передовой»</p>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SectionAdvantages"
    }
</script>

<style scoped>

</style>